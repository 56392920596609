<template>
    <div class="MainHeader wrap_header">
        <div class="header_top">
            <div class="inner_wrap">
                <h1>
                    <a>
                        <img src="../assets/logo.png" alt="" style="width: 30px">
                        <span>서울시 병상 시스템</span>
                        <span v-if="rootUrl == 'http://49.50.172.11:10102'">개발서버</span>
                    </a>
                </h1>
                <div class="utilArea" v-if="userDataAdminId">
                    <div class="memberUser">
                        <span class="iconUser"></span><a title="개인정보수정" style="text-decoration: underline;">
                        <span class="name">{{userDataAdminName}}</span></a>님 오신걸 환영합니다. &nbsp;
                        <a class="btn2" title="로그아웃" @click="logOutFunc">로그아웃</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="header" v-if="userDataAdminId">
            <template v-if="userDataAdminType == 'ADMIN'">
                <div class="inner_wrap">
                    <h1 class="logo"><a>서울시 병상 시스템</a></h1>
                    <div class="gnb">
                        <ul id="tpMenu">
                            <li :class="menuActive == 'dashboard' ? 'active':''">
                                <a @click="menuLink('AdminDashboardIndex')">대시보드</a>
                            </li>
                            <li :class="menuActive == 'confirmed' ? 'active':''">
                                <a @click="menuLink('AdminConfirmedList')">확진자관리</a>
                            </li>

                            <li :class="menuActive == 'facility' ? 'active':''" v-if="!['G1105'].includes(userDataAdminPermission)">
                                <a @click="menuLink('AdminFacilityList')">시설관리</a>
                            </li>
                            <li :class="menuActive == 'statistics' ? 'active':''">
                                <a>통계현황</a>
                                <ul>
                                    <li @click="menuLink('AdminStatisticsSeverity')"><span>중증도별 통계</span></li>
                                    <li @click="menuLink('AdminStatisticsArea')"><span>지역별 통계</span></li>
                                    <li @click="menuLink('AdminStatisticsFacility')"><span>시설별 통계</span></li>
                                    <li @click="menuLink('AdminStatisticsUnassign')"><span>미배정 사유별 통계</span></li>
                                    <li @click="menuLink('AdminStatisticsDateassign')"><span>당일배정 시설별 통계</span></li>
                                    <li @click="menuLink('AdminStatisticsSelfcare')"><span>재택치료 사유별 통계</span></li>
                                </ul>
                            </li>
                            <li :class="menuActive == 'admin' ? 'active':''" v-if="['G1101', 'G1106'].includes(userDataAdminPermission)">
                                <a @click="menuLink('AdminAdminList')">환경설정</a>
                                <ul>
                                    <li @click="menuLink('AdminAdminList')"><span>관리자</span></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="inner_wrap">
                    <h1 class="logo"><a>서울시 병상 시스템</a></h1>
                    <div class="gnb">
                        <ul>
                            <li :class="menuActive == 'confirmed' ? 'active':''">
                                <a @click="menuLink('ConfirmedListManager')">배정자관리</a>
                            </li>
                            <li :class="menuActive == 'search' ? 'active':''">
                              <a @click="menuLink('ConfirmedListManagerSearch')">확진자검색</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'MainHeader',
        data() {
            return {
            }
        },
        props: ['menuActive'],
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {
        },
        methods: {

        }
    }
</script>
<style scoped>
    #gnb_container {position: fixed;left: 0;bottom: 0;right: 0;border-top: 1px solid #e5e5e5;background-color: #fff;z-index: 10;}
    #gnb_container li {float: left;width: 25%;text-align: center;}

    .wrap_header .header_top .inner_wrap h1 a img{ width: 112px; margin-top: 2px; }
    .wrap_header .header_top .inner_wrap h1 a span{ color: #fff;padding-left: 10px;font-size: 18px;position: absolute;top: 10px; }
    .wrap .header_top { height: 43px; background: #0e54a4; }
    .memberUser {color:#fff;}
    .memberUser span{color:#fff;}

    .wrap .header .inner_wrap .gnb > ul ul li span {padding: 8px 24px;font-size: 14px;line-height: 16px;}

    .wrap .header .inner_wrap .gnb > ul ul li span {
      text-align: left;
      padding: 8px 24px;
      display: block;
      color: #666666;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
      text-transform: uppercase;
      width: 182px;
      -webkit-transition: all 0.35s ease-in-out;
      transition: all 0.35s ease-in-out;
      cursor: pointer;
    }
</style>
