<template>
    <div class="Login">
<!--        <main-header menuActive="Home" />-->
<!--        <div class="loginLogo" style="text-align: center;">-->
<!--            <img src="../assets/logo.png" alt="">-->
<!--        </div>-->
        <div class="LoginWrap">
            <!-- 내부로 이동
            <div class="loginTop">
                <div class="title">서울시 병상 시스템</div>
                <div class="title text-center no-pdd-top" v-if="rootUrl == 'localhost:8080'">개발서버</div>
            </div>
            -->
            <div class="loginBox">
                <div class="loginInputIn">
                  <div class="loginLogo">
                    <img src="../assets/logo.png" alt="">
                    <p>서울시 병상 시스템</p>
                  </div>
                  <!-- 레이블 제거
                  <label class="Logintit">아이디</label> -->
                  <div class="inputWrap">
                      <input type="text" v-model="loginUserId" class="loginipt inputWide" placeholder="아이디를 입력해 주세요.">
                  </div>
                  <!-- 레이블 제거
                  <label class="Logintit">비밀번호</label>-->
                  <div class="inputWrap">
                      <input type="password" v-model="loginUserPw" class="loginipt inputWide" placeholder="비밀번호를 입력해 주세요." v-on:keyup.enter="loginFunc">
                  </div>
                  <button type="button" class="btnLogin mt20" @click="loginFunc">로그인</button>
                  <!-- 아이디 저장
                  <div class="Logintit2">
                  <input type="checkbox" id="c_save" class="css-checkbox" checked="">
                  <label for="c_save" class="css-label"></label>아이디 저장
                  </div>-->
                </div>
            </div>

            <div class="loginImg">
              <img src="../assets/images/login_img.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import mainHeader from '@/components/MainHeader.vue';

    export default {
        name: 'AdminLogin',
        data() {
            return {
                loginUserId:'', // 로그인 아이디
                loginUserPw:'', // 로그인 비밀번호
            }
        },
        components: { }, //mainHeader },
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
            this.storageClear();
        },
        // $el 생성후
        mounted() {
        },
        methods: {
            loginFunc(){
                if (!this.loginUserId) {
                    this.showAlert('', '아이디 입력 후 진행하세요.');
                    return false;
                }

                if (!this.loginUserPw) {
                    this.showAlert('', '비밀번호 입력 후 진행하세요.');
                    return false;
                }

                let data = {
                  fId : this.loginUserId,
                  fPw : this.loginUserPw
                }

                // formData.append('api_name', 'login');
                this.ajaxPost('nocMember/login', data,'loginResult');
            },
            loginResult(response){
                let result = response;

                console.log(result);
                console.log(result.code);

                // 정상일 경우
                if (result.code == 200){
                    let storage = {};
                    storage.adminId = result.list.fid;
                    storage.adminName = result.list.fadminName;
                    storage.adminPermission = result.list.fadminType;

                    // G1106 : 시스템운영담당자
                    // G1105 : 기타담당자
                    // G1107 : 모니터링
                    if (result.list.fadminType == 'G1106' || result.list.fadminType == 'G1105' || result.list.fadminType == 'G1107'){
                      storage.adminType = 'ADMIN';
                      this.storageSet('userInfo', storage);
                      this.$router.push('/admin/dashboard');
                    }else{
                      storage.adminType = 'MANAGER';
                      this.storageSet('userInfo', storage);
                      this.$router.push('/manager/confirmedList');
                    }

                }
            },
        }
    }
</script>

<style scoped>
.loginLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 60px;
}
.loginLogo > img {
  //max-width: 200px;
  //margin: 50px auto 25px;
  width: 80px;
  margin-bottom: 10px;
}
.loginLogo > p {
  font-size: 24px;
  font-weight: 600;
  color: black;
}

.Login {
  background-color: #D5EAFD;
  -webkit-box-align: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
.LoginWrap {
  width: 900px;
  height: 600px;
  background-color: #fff;
  border-radius: 15px;
  -webkit-box-shadow: 0 15px 60px rgb(1 1 97 / 20%);
  box-shadow: 0 15px 60px rgb(1 1 97 / 20%);
  display: flex;
  flex-direction: row;
}

.loginBox {
  width: 50%;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  float: left;
}
.loginInputIn {
  width: 310px;
}
.loginImg {
  width: 50%;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  float: left;

  position: relative;
  background-color: #9BD1FF;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.loginImg > img {
  width: 128%;
  position: absolute;
  left: 0;
  top: 74px;
}
</style>
